import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	color: ${CSSVars.black};
	.form-wrapper {
		&.shown {
			opacity: 1;
			transform: translate(-50%, calc(-50% + 0px));
			z-index: 110;
			pointer-events: all;
		}

		.input-wrapper {
			gap: 20px;
			display: flex;
			flex-direction: column;

			input {
				padding: 7px 15px;
				color: rgb(57, 57, 57);
				border-radius: 5px;
				border: 1px solid #dfdfdf;
				font-size: 16px;
				font-weight: 300;
				font-family: 'Poppins', sans-serif;

				&:focus {
					border: 1px solid #9a9a9a;
				}
				&:placeholder {
					color: #c6cbdf;
				}
			}

			button {
				font-family: 'Poppins', sans-serif;
				background-color: ${CSSVars.blue};
				border: none;
				padding: 8px 20px;
				border-radius: 5px;
				font-size: 16px;
				font-weight: 500;
				cursor: pointer;
				color: white;
				transition: 0.2s;

				&:hover {
					opacity: 0.8;
				}
			}
		}

		h4 {
			font-size: 22px;
			margin-bottom: 15px;
			font-weight: 500;
		}

		p {
			max-width: 395px;
			font-weight: 300;
			font-size: 16px;
			margin-bottom: 20px;
		}

		max-width: 100%;
		min-width: 300px;
		pointer-events: none;
		background-color: #fff;
		border-radius: 10px;
		transition: 0.2s;
		opacity: 0;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, calc(-50% + 50px));
		padding: 25px;
	}

	.social-buttons {
		position: fixed;
		left: 0;
		top: 60%;
		z-index: 30;
		transform: rotate(90deg) translate(-50%, 263%);

		display: flex;
	}

	.btn {
		border: none;
		padding: 9px 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: ${CSSVars.blue};
		color: white;
		cursor: pointer;
		font-size: 16px;
		font-weight: 500;
		font-family: 'Lato', sans-serif;
		transition: 0.2s;
		letter-spacing: 1px;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		border-radius: 5px 0px 0px 0px;

		&:hover {
			opacity: 0.8;
			border-radius: 0;
		}

		&.social {
			border-radius: 0;
			padding: 8px;
		}

		&.linkedin {
			background-color: #0077b7;
		}

		&.facebook {
			background-color: #1877f2;
		}

		&.twitter {
			background-color: #010101;
		}

		&.youtube {
			background-color: #FF0000;
			border-radius: 0 5px 0px 0px;
		}

		display: none;

		@media screen and (min-width: ${CSSVars.breakpointSM}) {
			display: flex;
		}
	}
`;
