'use client';

import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { ReactDimmer } from 'react-dimmer';
import { StyledDiv } from "./newsLetterForm.styles";
import { toast } from 'react-toastify';
import { CustomLink } from "../link/link.comp";
import { GrLinkedinOption } from "@react-icons/all-files/gr/GrLinkedinOption";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";

export const NewsLetterForm = () => {
    const url = 'https://commoninja.us11.list-manage.com/subscribe/post?u=4076742add55bb43380b94b22&amp;id=1cc0fef740';
    const [email, setEmail] = useState<string>('');
    const [isShown, setIsShown] = useState<boolean>(false);

    function notify(status: string) {
        setEmail('');
        if (!email) {
            toast.info('Email is required.');
            return;
        }
        if (status === 'success') {
            toast.success('Great Success 🎉');
            setIsShown(false);
            setEmail('');
            return;
        }
        setEmail('');
        toast.error(`Oops, something went wrong, please try again.`);
    }

    return (
        <>
            <StyledDiv >
                <div className={`form-wrapper ${isShown ? 'shown' : ''}`}>
                    <h4>Join Our Newsletter!</h4>
                    <p>Keep up to date with our latest blog posts, new widgets and features, and the Common Ninja Developer Platform.</p>
                    <MailchimpSubscribe url={url} render={({ status, subscribe, message }) => (
                        <form className="input-wrapper" onSubmit={(e) => { e.preventDefault(); subscribe({ EMAIL: email }); notify(status as string); }}>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Your email' type="email" />
                            <button>Subscribe!</button>
                        </form>
                    )} />
                </div>
                <div className="social-buttons">
                    <button className="btn" onClick={() => { setIsShown(!isShown); }}>
                        Subscribe
                    </button>
                    <CustomLink href="https://www.linkedin.com/company/common-ninja" target="_blank" className="btn linkedin social">
                        <GrLinkedinOption size={30} style={{ rotate: '-90deg' }} />
                    </CustomLink>
                    <CustomLink href="https://www.facebook.com/CommonNinja/" target="_blank" className="btn facebook social">
                        <FaFacebookF size={28} style={{ rotate: '-90deg' }} />
                    </CustomLink>
                    <CustomLink href="https://x.com/commonninja" target="_blank" className="btn twitter social">
                        {renderXIcon()}
                    </CustomLink>
                    <CustomLink href="https://www.youtube.com/@commonninja" target="_blank" className="btn youtube social">
                        <FaYoutube size={28} style={{ rotate: '-90deg' }} />
                    </CustomLink>
                </div>
            </StyledDiv >
            <ReactDimmer blur={2} opacity={0.4} isOpen={isShown} exitDimmer={() => { setIsShown(false); setEmail(''); }} />
            {/* <ToastContainer position='top-center' autoClose={2000} /> */}
        </>
    );
};
function renderXIcon() {
    return (
        <svg style={{ rotate: '-90deg' }} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
    );
}